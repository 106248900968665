<template>
  <q-layout view="hHh Lpr fFf">
    <q-header class="text-black">
      <q-toolbar>
        <!--q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="fas fa-bars"
        / -->
        <q-toolbar-title class="typeWriter">
          <q-icon size="xl" name="img:img\icons\favicon.ico" />eZida
        </q-toolbar-title>
        <q-btn
          flat
          dense
          round
          @click="rightDrawerOpen = !rightDrawerOpen"
          aria-label="Menu"
          icon="fas fa-bars"
        />
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" side="left" bordered>
      <!-- drawer content  show-if-above-->
    </q-drawer>

    <q-drawer v-model="rightDrawerOpen" side="right" bordered>
      <!-- drawer content show-if-above-->
    </q-drawer>

    <q-page-container> <no-cookies /> <HelloWorld /> </q-page-container>

    <q-footer class="text-black">
      <q-toolbar>
        <q-toolbar-title><q-icon name="fas fa-bars" /> </q-toolbar-title>
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import HelloWorld from "./components/HelloWorld.vue";
import NoCookies from "./components/general/noCookies.vue";

export default {
  name: "LayoutDefault",

  components: {
    HelloWorld,
    NoCookies,
  },

  setup() {
    return {
      leftDrawerOpen: ref(false),
      rightDrawerOpen: ref(false),
    };
  },
};
</script>

<style>
.typeWriter {
  font-family: "Special Elite", "IBM Plex Mono", monospace;
  font-size: 30px !important;
  text-align: center;
}
</style>
